const Flickity = require('flickity-imagesloaded');
const debounce = require('debounce');

class BlockFeaturedItemsSlider extends window.HTMLDivElement {
  constructor (self) {
    self = super(self);

    this.initialized = false;

    this.sliderContainerElement = this.querySelector('.featured-items-slider__slider-container');
    this.sliderItemElements = this.sliderContainerElement.querySelectorAll('.featured-items-slider__item');

    this.sliderCounterClass = 'featured-items-slider__slider-counter';
    this.sliderCounterSeparatorClass = 'featured-items-slider__slider-counter-separator';
    this.sliderCurrentSlideClass = 'featured-items-slider__slider-current-slide';
    this.sliderTotalSlidesClass = 'featured-items-slider__slider-total-slides';

    this.breakpointLarge = 1280;
    this.breakpointSmall2 = 980;
    this.breakpointXSmall3 = 680;
    this.mediaQueryLarge = window.matchMedia(`(min-width: ${this.breakpointLarge}px)`);
    this.mediaQuerySmall2 = window.matchMedia(`(min-width: ${this.breakpointSmall2}px) and (max-width: ${this.breakpointLarge - 1}px)`);
    this.mediaQueryXSmall3 = window.matchMedia(`(min-width: ${this.breakpointXSmall3}px) and (max-width: ${this.breakpointSmall2 - 1}px)`);

    this.flikityGrouping = 0;
    this.flickity = null;
    this.setFlickity();
    const boundResizeHandler = debounce(this.setFlickity.bind(this), 200).bind(this);
    window.addEventListener('resize', boundResizeHandler);

    return self;
  }

  set initialized(value) {
    if (value) {
      this.classList.add('featured-items-slider--initialized');
    }
    else {
      this.classList.remove('featured-items-slider--initialized');
    }
    this._initialized = value;
  }
  get initialized() {
    //
    return this._initialized;
  }

  setSlideCount() {
    if (!this.querySelector('.' + this.sliderCounterClass)) {
      const sliderCounterElement = document.createElement('div');
      sliderCounterElement.classList.add(this.sliderCounterClass);
      
      const sliderCurrentSlideElement = document.createElement('span');
      sliderCurrentSlideElement.classList.add(this.sliderCurrentSlideClass);
      sliderCounterElement.appendChild(sliderCurrentSlideElement);

      const sliderCounterSeparatorElement = document.createElement('span');
      sliderCounterSeparatorElement.classList.add(this.sliderCounterSeparatorClass);
      sliderCounterSeparatorElement.innerHTML = '/';
      sliderCounterElement.appendChild(sliderCounterSeparatorElement);
      
      const sliderTotalSlidesElement = document.createElement('span');
      sliderTotalSlidesElement.classList.add(this.sliderTotalSlidesClass);
      sliderCounterElement.appendChild(sliderTotalSlidesElement);

      this.sliderContainerElement.appendChild(sliderCounterElement);
    }
    this.setCurrentSlide();
    this.setTotalSlides();
  }
  deleteSlideCount() {
    const sliderCounterElement = this.querySelector('.' + this.sliderCounterClass);
    if (sliderCounterElement) {
      sliderCounterElement.remove();
    }
  }
  setCurrentSlide() {
    const sliderCurrentSlideElement = this.querySelector('.' + this.sliderCurrentSlideClass);
    if (sliderCurrentSlideElement) {
      sliderCurrentSlideElement.innerHTML = this.getCurrentSlide();
    }
  }
  setTotalSlides() {
    const sliderTotalSlidesElement = this.querySelector('.' + this.sliderTotalSlidesClass);
    if (sliderTotalSlidesElement) {
      sliderTotalSlidesElement.innerHTML = this.getTotalSlides();
    }
  }
  getCurrentSlide() {
    if (this.flickity) {
      return this.flickity.selectedIndex + 1;
    }
  }
  getTotalSlides() {
    if (this.flickity) {
      return this.flickity.slides.length;
    }
  }
  removeHiddenFocus() {
  // Select all <a> tags within .flickity-slider
  const links = document.querySelectorAll('.flickity-slider a');

  // Loop through each link
  links.forEach(link => {
    // Check if the link has aria-hidden="true"
    if (link.getAttribute('aria-hidden') === "true") {
      // If it does, set tabindex to -1
      link.setAttribute('tabindex', '-1');
    } else {
      // If it doesn't, remove tabindex attribute
      link.removeAttribute('tabindex');
    }
  });
}

  setFlickity() {
    // console.log(this);
    // console.log('window.innerWidth:', window.innerWidth);
    // console.log('this.sliderItemElements.length:', this.sliderItemElements.length);
    // console.log('this.flikityGrouping:', this.flikityGrouping);
    // console.log('===== media query specific logging =====');
    this.initialized = false;

    if (this.mediaQueryLarge.matches) {
      // console.log('mediaQueryLarge (>=1280)');
      if (this.sliderItemElements.length > 4 && this.flikityGrouping !== 4) {
        // console.log('more than 4 items, not already in groups of 4');
        this.destroyFlickity();
        this.flikityGrouping = 4;
        this.flickity = new Flickity(this.sliderContainerElement, {
          pageDots: false,
          cellAlign: 'left',
          groupCells: 4,
          arrowShape: {
            x0: 20,
            x1: 65, y1: 50,
            x2: 70, y2: 45,
            x3: 30
          }
        });
        this.flickity.reloadCells();
        this.setSlideCount();
        this.removeHiddenFocus();
        this.flickity.on('change', this.setCurrentSlide.bind(this));
        this.flickity.on('change', this.removeHiddenFocus.bind(this));
        //this.removeHiddenFocus();
      }
      else if (this.sliderItemElements.length <= 4) {
        // console.log('less than or equal to 4 items');
        this.destroyFlickity();
      }
    }
    else if (this.mediaQuerySmall2.matches) {
      // console.log('mediaQuerySmall2 (>=980)');
      if (this.sliderItemElements.length > 3 && this.flikityGrouping !== 3) {
        // console.log('more than 3 items, not already in groups of 3');
        this.destroyFlickity();
        this.flikityGrouping = 3;
        this.flickity = new Flickity(this.sliderContainerElement, {
          pageDots: false,
          cellAlign: 'left',
          groupCells: 3,
          arrowShape: {
            x0: 20,
            x1: 65, y1: 50,
            x2: 70, y2: 45,
            x3: 30
          }
        });
        this.flickity.reloadCells();
        this.setSlideCount();
        this.flickity.on('change', this.setCurrentSlide.bind(this));
        this.flickity.on('change',  this.removeHiddenFocus());
      }
      else if (this.sliderItemElements.length <= 3) {
        // console.log('less than or equal to 3 items');
        this.destroyFlickity();
      }
    }
    else if (this.mediaQueryXSmall3.matches) {
      // console.log('mediaQueryXSmall3 (>=680)');
      if (this.sliderItemElements.length > 2 && this.flikityGrouping !== 2) {
        // console.log('more than 2 items, not already in groups of 2');
        this.destroyFlickity();
        this.flikityGrouping = 2;
        this.flickity = new Flickity(this.sliderContainerElement, {
          pageDots: false,
          cellAlign: 'left',
          groupCells: 2,
          arrowShape: {
            x0: 20,
            x1: 65, y1: 50,
            x2: 70, y2: 45,
            x3: 30
          }
        });
        this.flickity.reloadCells();
        this.setSlideCount();
        this.removeHiddenFocus();
        this.flickity.on('change', this.setCurrentSlide.bind(this));

      }
      else if (this.sliderItemElements.length <= 2) {
        // console.log('less than or equal to 2 items');
        this.destroyFlickity();
      }
    }
    else if (this.sliderItemElements.length > 1) {
      if (this.flikityGrouping !== 1) {
        // console.log('no mediaQuery (<680)');
        // console.log('more than 1 item, not already in groups of 1');
        this.destroyFlickity();
        this.flikityGrouping = 1;
        this.flickity = new Flickity(this.sliderContainerElement, {
          pageDots: false,
          cellAlign: 'left',
          arrowShape: {
            x0: 20,
            x1: 65, y1: 50,
            x2: 70, y2: 45,
            x3: 30
          }
        });
        this.flickity.reloadCells();
        this.setSlideCount();
        this.flickity.on('change', this.setCurrentSlide.bind(this));
        this.removeHiddenFocus();
      }
    }
    else {
      // console.log('no mediaQuery (<680)');
      // console.log('only 1 item');
      this.destroyFlickity();
    }

    this.initialized = true;
  }

  destroyFlickity() {
    if (this.flickity) {
      this.flickity.destroy();
      this.deleteSlideCount();
      this.flickity = null;
      this.flikityGrouping = 0;
    }
  }
}



window.customElements.define('flynt-block-featured-items-slider', BlockFeaturedItemsSlider, { extends: 'div' });