/*
	transitionAuto (v1.0.1)
	A function to allow for width and height transitions to and from auto.
	https://github.com/alexspirgel/transitionAuto
*/
const transitionAuto = (options) => {

	// Define console message prefix.
	const logPrefix = 'transitionAuto: ';

	/*
		Validate the options object to ensure only allowed values were passed.
	*/
	const validateOptions = (options) => {
		// If element is not a selector or an element.
		if (typeof options.element !== 'string' &&
		!options.element instanceof Element &&
		!options.element instanceof HTMLDocument) {
			console.error(logPrefix + '`options.element` is not valid. It must be a selector (string) or an element (object).');
			return false;
		}
		// If innerElement not a selector, element, or undefined.
		if (typeof options.innerElement !== 'string' &&
		!options.innerElement instanceof Element &&
		!options.innerElement instanceof HTMLDocument &&
		typeof options.innerElement !== 'undefined') {
			console.error(logPrefix + '`options.innerElement` is not valid. It must be a selector (string) or an element (object).');
			return false;
		}
		// If both width and height are undefined.
		if (typeof options.width === 'undefined' &&
		typeof options.height === 'undefined') {
			console.error(logPrefix + 'at least one of `options.width` and `options.height` must be a string or number.');
			return false;
		}
		// If width is not a string, number, or undefined.
		if (typeof options.width !== 'string' &&
		typeof options.width !== 'number' &&
		typeof options.width !== 'undefined') {
			console.error(logPrefix + '`options.width` must be a string, number, or undefined.');
			return false;
		}
		// If height is not a string, number, or undefined.
		if (typeof options.height !== 'string' &&
		typeof options.height !== 'number' &&
		typeof options.height !== 'undefined') {
			console.error(logPrefix + '`options.height` must be a string, number, or undefined.');
			return false;
		}
		// If debug is not a boolean or undefined.
		if (typeof options.debug !== 'boolean' &&
		typeof options.debug !== 'undefined') {
			console.error(logPrefix + '`options.debug` must be a boolean or undefined.');
			return false;
		}
		// If optionsValidation is not a boolean or undefined.
		if (typeof options.optionsValidation !== 'boolean' &&
		typeof options.optionsValidation !== 'undefined') {
			console.error(logPrefix + '`options.optionsValidation` must be a boolean or undefined.');
			return false;
		}
		// If options are valid.
		return true;
	};

	/*
		Normalize the options into a single, expected format.
	*/
	const normalizeOptions = (options) => {
		// If element is a selector (string).
		if (typeof options.element === 'string') {
			// Get the element from the selector.
			let element = document.querySelector(options.element);
			// If the selector matched no elements.
			if (element === null) {
				console.error(logPrefix + '`options.element` selector does not return any matches');
				return false;
			}
			// Set the element to the matched element.
			options.element = element;
		}
		// If innerElement is a selector (string).
		if (typeof options.innerElement === 'string') {
			// Get the inner element from the selector.
			let innerElement = document.querySelector(options.innerElement);
			// If the selector matched no elements.
			if (innerElement === null) {
				console.error(logPrefix + '`options.innerElement` selector does not return any matches');
				return false;
			}
			// Set the element to the matched element.
			options.innerElement = innerElement;
		}
		// If innerElement is not defined.
		else if (typeof options.innerElement === 'undefined') {
			// Set innerElement to the first child element.
			options.innerElement = options.element.firstElementChild;
		}
		// If the innerElement is not contained by the main element.
		if (!options.element.contains(options.innerElement)) {
			console.error(logPrefix + '`options.innerElement` must be contained by `options.element`.');
			return false;
		}
		// Set the default unit.
		const defaultUnit = 'px';
		// If the width value is a number.
		if (typeof options.width === 'number') {
			// Convert into a string using the default unit.
			options.width += defaultUnit;
		}
		// If the height value is a number.
		if (typeof options.height === 'number') {
			// Convert into a string using the default unit.
			options.height += defaultUnit;
		}
		// If optionsValidation is undefined.
		if (typeof options.optionsValidation === 'undefined') {
			// Set optionsValidation to true.
			options.optionsValidation = true;
		}
		// Return the normalized options.
		return options;
	};

	/*
		Explicitly set the current width/height as inline pixel value styles.
	*/
	const forceCurrentDimensions = (options) => {
		// Get computed styles.
		computedStyles = getComputedStyle(options.element);
		// If element width is defined.
		if (typeof options.width !== 'undefined') {
			// Set the element width equal to the computed style width.
			options.element.style.width = computedStyles.width;
		}
		// If element height is defined.
		if (typeof options.height !== 'undefined') {
			// Set the element height equal to the computed style height.
			options.element.style.height = computedStyles.height;
		}
		// This line does nothing but force the element to redraw so the transition works properly.
		options.element.offsetWidth;
	};

	/*
		Handle transitionend events for the width property.
	*/
	const transitionendHandlerWidth = (event) => {
		// If the event property name is width.
		if (event.propertyName === 'width') {
			// Remove the previous width event handler if it exists.
			event.target.removeEventListener('transitionend', event.target.transitionAuto.eventHandlerWidth);
			// Set the element style width to auto.
			event.target.style.width = 'auto';
		}
	};

	/*
		Handle transitionend events for the height property.
	*/
	const transitionendHandlerHeight = (event) => {
		// If the event property name is height.
		if (event.propertyName === 'height') {
			// Remove the previous height event handler if it exists.
			event.target.removeEventListener('transitionend', event.target.transitionAuto.eventHandlerHeight);
			// Set the element style height to auto.
			event.target.style.height = 'auto';
		}
	};

	/*
		Set the new dimensions of the element.
	*/
	const setDimensions = (options) => {
		// Initialize counding client rect variable.
		let innerBoundingClientRect;
		// If option width or height is auto.
		if (options.width === 'auto' ||
		options.height === 'auto') {
			// Get inner element bounding client rect for accurate dimensions.
			innerBoundingClientRect = options.innerElement.getBoundingClientRect();
		}
		// If options include a width.
		if (typeof options.width !== 'undefined') {
			// If option width is auto and the element width doesn't equal the inner element width.
			if (options.width === 'auto' &&
			options.element.style.width !== innerBoundingClientRect.width + 'px') {
				// Add the width transitionend event listener.
				options.element.addEventListener('transitionend', transitionendHandlerWidth);
				// Attach a reference to the event handler so we can remove the event listener on overlapping executions.
				options.element.transitionAuto.eventHandlerWidth = transitionendHandlerWidth;
				// Set element width equal to the innerElement width.
				options.element.style.width = innerBoundingClientRect.width + 'px';
			}
			// If option width is not auto.
			else {
				options.element.style.width = options.width;
			}
		}
		// If options include a height.
		if (typeof options.height !== 'undefined') {
			// If option height is auto and the element height doesn't equal the inner element height.
			if (options.height === 'auto' &&
			options.element.style.height !== innerBoundingClientRect.height + 'px') {
				// Add the height transitionend event listener.
				options.element.addEventListener('transitionend', transitionendHandlerHeight);
				// Attach a reference to the event handler so we can remove the event listener on overlapping executions.
				options.element.transitionAuto.eventHandlerHeight = transitionendHandlerHeight;
				// Set element height equal to the innerElement height.
				options.element.style.height = innerBoundingClientRect.height + 'px';
			}
			// If option height is not auto.
			else {
				options.element.style.height = options.height;
			}
		}
	};

	if (options.debug) {
		console.log('========== transitionAuto Debug ==========');
	}

	/*
		Check for options.
	*/
	// If options are missing.
	if (!options) {
		console.error(logPrefix + 'missing options.');
		return false;
	}
	if (options.debug) {
		console.log(logPrefix + 'options:');
		console.log(options);
	}

	/*
		Validate options.true, false
	*/
	// If optionsValidation is true or debug is true.
	if (options.optionsValidation || options.debug) {
			// If options are invalid.
		if (!validateOptions(options)) {
			console.error(logPrefix + 'options are invalid.');
			return false;
		}
		if (options.debug) {
			console.log(logPrefix + 'options are valid');
		}
	}

	/*
		Normalize options.
	*/
	// Normalize option values.
	options = normalizeOptions(options);
	// If the options normalized with an error.
	if (!options) {
		console.error(logPrefix + 'error normalizing options, see above output.');
		return false;
	}
	if (options.debug) {
		console.log(logPrefix + 'normalized options:');
		console.log(options);
	}

	/*
		Remove previous transitionAuto event listeners.
	*/
	// If a transitionAuto object exists from a previous execution.
	if (options.element.transitionAuto) {
		// If a width event handler reference exists from a previous execution.
		if (options.element.transitionAuto.eventHandlerWidth) {
			// Remove the existing 'transitionend' event listener.
			options.element.removeEventListener('transitionend', options.element.transitionAuto.eventHandlerWidth);
		}
		// If a height event handler reference exists from a previous execution.
		if (options.element.transitionAuto.eventHandlerHeight) {
			// Remove the existing 'transitionend' event listener.
			options.element.removeEventListener('transitionend', options.element.transitionAuto.eventHandlerHeight);
		}
	}

	/*
		Add data to the element.
	*/
	// Initialize a namespace on the element.
	options.element.transitionAuto = {};
	// Reference the options object from the element.
	options.element.transitionAuto.options = options;

	/*
		Perform the actual dimension changing.
	*/
	// Ensure current dimension(s) are represented in non-auto values.
	forceCurrentDimensions(options);
	// Set the desired dimensions.
	setDimensions(options);
};

module.exports = transitionAuto;