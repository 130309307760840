import $ from 'jquery'
import './ace-accordion'

class BlockAccordion extends window.HTMLDivElement {
  constructor (self) {
    self = super(self)
    self.$ = $(self)

    let accordion = new AceAccordion({
        selectors: {
          accordion: '.accordion__panel'
        },
        heading_trigger_selector: '.accordion__item-trigger',
        debug: false
      }
    );

    return self
  }
}


window.customElements.define('flynt-block-accordion', BlockAccordion, { extends: 'div' })

var accordiontrigger = $('.accordion__item-trigger');
var expandedstate = accordiontrigger.attr('aria-expanded');

$('.accordion__item-trigger').click(function(){
 

  if (expandedstate == true) {
  $(this).attr("aria-expanded", "false");
  expandedstate = false;
  }
  else {
    $(this).attr("aria-expanded", "true");
    expandedstate = true;
  }
  $('svg.chevron-down',this).toggleClass('flip');
})