import $ from 'jquery'

let frame = document.querySelector('.hero-banner__picture-zoom');
let homeframe = document.querySelector('.hero-banner__picture-zoom-home');
if (homeframe) {
    // Extract the number from the class list
    var classList = homeframe.className.split(' ');
    var scaleValue = classList.find(cls => !isNaN(cls)) || 1; // Default to 1 if no number is found
function zoomImgHome() {
    // homeframe.classList.add("frame-zoomed-home");
    // Use backticks for template literals
   if (homeframe.classList.contains('out')) {
        homeframe.style.transform = 'scale(1)';
    } else {
        homeframe.style.transform = `scale(${scaleValue})`;
    }
    homeframe.style.filter = 'brightness(100%)';
}
window.addEventListener('load', zoomImgHome, false);

}
function zoomImg() {
    frame.classList.add("frame-zoomed");
}


if (frame) {
    window.addEventListener('load', zoomImg, false);
}


