import $ from 'jquery'

class NavigationMain extends window.HTMLDivElement {
  constructor (self) {
    self = super(self)
    self.$ = $(self)
    self.resolveElements()
    self.initEvents()
    return self
  }

  resolveElements () {
    this.$navHeadings = $('.menu-item', this)
  }

  initEvents () {
    this.$navHeadings.focusin(this.openNavMenusDesktop.bind(this))
    this.$navHeadings.mouseover(this.openNavMenusDesktop.bind(this))
    this.$navHeadings.mouseleave(this.closeNavMenusDesktop.bind(this))
    this.$navHeadings.focusout(this.closeNavMenusDesktop.bind(this))

    this.$navHeadings.find('.menu-main__submenu')
      .focusin(this.openSelfDesktop.bind(this))
      .mouseover(this.openSelfDesktop.bind(this))
      .mouseleave(this.closeSelfDesktop.bind(this))
      .focusout(this.closeSelfDesktop.bind(this))
  }

  openNavMenusDesktop (e) {
    if (window.matchMedia('(min-width: 1024px)').matches) {
      $(e.currentTarget).children('.menu-main__submenu').addClass('open')
    }
  }

  closeNavMenusDesktop (e) {
    if (window.matchMedia('(min-width: 1024px)').matches) {
      $(e.currentTarget).children('.menu-main__submenu').removeClass('open')
    }
  }

  openSelfDesktop (e) {
    if (window.matchMedia('(min-width: 1024px)').matches) {
      $(e.currentTarget).addClass('open')
    }
  }

  closeSelfDesktop (e) {
    if (window.matchMedia('(min-width: 1024px)').matches) {
      $(e.currentTarget).removeClass('open')
    }
  }
}

window.customElements.define('flynt-navigation-main', NavigationMain, { extends: 'nav' })
