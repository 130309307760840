// import InstagramFeed from './InstagramFeed.js';
const Flickity = require('flickity-imagesloaded');
const debounce = require('debounce');

class BlockInstagramFeed extends window.HTMLDivElement {
  constructor(self) {
    self = super(self);

    const mediaQueryXSmall2 = window.matchMedia('(min-width: 570px)');
    let instagramFlickity;

		const handleSlider = () => {
			if (mediaQueryXSmall2.matches) {
				if (instagramFlickity) {
					instagramFlickity.destroy();
				}
			}
			else {
				const sliderElement = this.querySelector('.instagram_gallery');
				if (sliderElement) {
					instagramFlickity = new Flickity(sliderElement, {
						autoplay: 3000,
						pageDots: false,
						imagesLoaded: true,
						arrowShape: { 
						  x0: 20, y0: 0,
						  x1: 70, y1: 50,
						  x2: 75, y2: 45,
						  x3: 30, y3: 0,
						}
					});
				}
			}
		};

		// new InstagramFeed({
		// 	'username': 'cornell.law.school',
		// 	'container': this,
		// 	'display_profile': false,
		// 	'display_biography': false,
		// 	'display_gallery': true,
		// 	'display_igtv': false,
		// 	'styling': false,
		// 	'items': 6
		// });

		const sliderInitInterval = setInterval(() => {
			if (this.querySelector('.instagram_gallery')) {
				handleSlider();
				clearInterval(sliderInitInterval);
			}
		}, 200);

		window.onresize = debounce(handleSlider, 200);
		
    return self;
  }
}

window.customElements.define('flynt-block-instagram-feed', BlockInstagramFeed, {extends: 'div'});
