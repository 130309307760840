import $ from 'jquery'
class ElementBreadcrumbs extends window.HTMLDivElement {
  constructor (self) {
    self = super(self)
    self.$ = $(self)
    self.resolveElements()
    return self
  }

  resolveElements () {
  }
}

window.customElements.define('flynt-element-breadcrumbs', ElementBreadcrumbs, {extends: 'div'})