import transitionAuto from './scripts/transitionAuto';

class MenuFooter extends window.HTMLDivElement {
  constructor (self) {
    self = super(self);
    self.initMobileToggles();
    return self;
  }
  initMobileToggles() {
    const toggleSelector = '.footer-menu__utility-links-toggle, .footer-menu__footer-column-2-toggle';
    const toggleElements = this.querySelectorAll(toggleSelector);
    for (const toggleElement of toggleElements) {
      toggleElement.addEventListener('click', (event) => {
        let heightValue;
        if (toggleElement.nextElementSibling.style.height === 'auto') {
          heightValue = '0';
          toggleElement.classList.remove('opened');
        }
        else {
          heightValue = 'auto';
          toggleElement.classList.add('opened');
        }
        transitionAuto({
          element: toggleElement.nextElementSibling,
          height: heightValue
        });
      });
    }
  }
}

window.customElements.define('menu-footer', MenuFooter, { extends: 'nav' })