const Flickity = require('flickity-imagesloaded');

class BlockImageCarousel extends window.HTMLDivElement {
  constructor (self) {
    self = super(self);

    this.carouselContainerElement = this.querySelector('.image-carousel__items');
    this.carouselItemElements = this.carouselContainerElement.querySelectorAll('.image-carousel__item');
    this.carouselButtonPrevious = this.querySelector('.image-carousel__button-previous');
    this.carouselButtonNext = this.querySelector('.image-carousel__button-next');
    this.carouselCounterCurrentElement = this.querySelector('.image-carousel__counter-value-current');
    this.carouselCounterTotalElement = this.querySelector('.image-carousel__counter-value-total');

    this.flickity = new Flickity(this.carouselContainerElement, {
      pageDots: false,
      prevNextButtons: false,
      imagesLoaded: true
    });

    this.carouselButtonPrevious.addEventListener('click', (event) => {
      this.flickity.previous();
    });
    this.carouselButtonNext.addEventListener('click', (event) => {
      this.flickity.next();
    });

    const counterTotal = this.carouselItemElements.length;
    this.carouselCounterTotalElement.innerHTML = counterTotal;
    
    this.carouselCounterCurrentElement.innerHTML = 1;
    this.flickity.on('change', (index) => {
      this.carouselCounterCurrentElement.innerHTML = index + 1;
    });

    return self;
  }
}

window.customElements.define('image-carousel', BlockImageCarousel, {extends: 'div' });
