import $ from 'jquery'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import TraversableMenu from 'traversable_menu'
import 'traversable_menu/traversable_menu.css'

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});




class BlockMenuMobile extends window.HTMLElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.bindFunctions()
    this.bindEvents()
    this.resolveElements()
  }

  bindFunctions () {
    this.triggerMenu = this.triggerMenu.bind(this)
    this.toggleButton = this.toggleButton.bind(this)
  }

  bindEvents () {
    this.$.on('click', '[data-toggle-menu]', this.triggerMenu)
    this.$.on('click', '[aria-controls]', this.toggleButton)
  }

  resolveElements () {
    this.$menu = $('.menu', this)
  }

  connectedCallback () {}

  triggerMenu (e) {
    this.$.toggleClass('flyntComponent-menuIsOpen')
    if (this.$.hasClass('flyntComponent-menuIsOpen')) {
      disableBodyScroll(this.$menu.get(0))
      $('.mobile-menu').attr('aria-hidden', 'false')
      $('.mobile-menu a').attr('tabindex', '')
      $('.mobile-menu button').attr('tabindex', '')
    } else {
      enableBodyScroll(this.$menu.get(0))
      $('.mobile-menu').attr('aria-hidden', 'true')
      $('.mobile-menu a').attr('tabindex', '-1')
      $('.mobile-menu button').attr('tabindex', '-1')
    }
  }

  toggleButton (e) {
    const $button = $(e.currentTarget)
    if (!$button.hasClass("mobile-menu__button")) {
        if ($button.attr('aria-expanded') === 'true') {
          $button.removeClass('btn-open')
          $button.parent().removeClass('menu-item--open')
          $button.attr('aria-expanded', 'false')
          $button.next().attr('aria-hidden', 'true').slideUp()
        } else {
          $button.addClass('btn-open')
          $button.parent().addClass('menu-item--open')
          $button.attr('aria-expanded', 'true')
          $button.next().attr('aria-hidden', 'false').slideDown()
        }
  }
    else {
        if ($button.attr('aria-expanded') === 'true') {
          $button.attr('aria-expanded', 'false')
        } else {
          $button.attr('aria-expanded', 'true')
        }

    } 
  }
    
  }



// Adding the ability to collapse nav menu by clicking esc

document.addEventListener('keyup', function(e) {
  if (e.key === 'Escape') {
    if ($('.mobile-menu').hasClass('flyntComponent-menuIsOpen')) {
    $('.mobile-menu').removeClass('flyntComponent-menuIsOpen')
    }

  }
});

    
   

window.customElements.define('flynt-menu-mobile', BlockMenuMobile, {
  extends: 'nav'
})
