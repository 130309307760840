const Flickity = require('flickity-fade');

class BlockTwitterFeed extends window.HTMLDivElement {
  constructor (self) {
    self = super(self);

    this.sliderContainerElement = this.querySelector('.ctf-tweets');
    this.sliderItemElements = this.sliderContainerElement.querySelectorAll('.ctf-item');

    this.externalLinkSVG = '<svg version="1.1" class="external-link-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.8 12.8" style="enable-background:new 0 0 12.8 12.8;" xml:space="preserve"><path class="external-link-svg-fill" d="M0,0v12.8h12.8V9.6h-1.6v1.6H1.6V1.6h1.6V0H0z M6.4,0l2.4,2.4l-4,4L6.4,8l4-4l2.4,2.4V0H6.4z"/></svg>';

    for (let sliderItemElement of this.sliderItemElements) {
      let itemText = sliderItemElement.querySelector('.ctf-tweet-text');
      itemText.innerHTML = itemText.innerHTML + this.externalLinkSVG;
    }

    this.flickity = new Flickity(this.sliderContainerElement, {
      adaptiveHeight: true,
      autoPlay: 5000,
      cellAlign: 'left',
      draggable: false,
      fade: true,
      pageDots: false,
      prevNextButtons: false
    });

    return self;
  }
}

window.customElements.define('flynt-block-twitter-feed', BlockTwitterFeed, { extends: 'div' });
