import $ from 'jquery';

class BlockFacultyDirectory extends window.HTMLDivElement {
  
  constructor (self) {
    self = super(self);
    self.$ = $(self);
    self.init();
    return self;
  }

  init() {
    this.$ = $(this);
    this.bindFunctions();
    this.bindEvents();
    this.resolveElements();
  }

  bindFunctions() {
    this.toggleButton = this.toggleButton.bind(this);
  }

  bindEvents() {
    this.$.on('click', '.faculty-directory__filter-tab button', this.toggleButton);
  }

  resolveElements() {
    this.$menus = $('.faculty-directory__filter-facet', this);
    this.$buttons = $('.faculty-directory__filter-tab button', this);
  }

  openFilterPanel($button, $menu) {
    this.closeFilterPanels();
    $button.addClass('btn-open');
    $button.attr('aria-expanded', 'true');
    $menu.addClass('menu-item--open');
    $menu.attr('aria-hidden', 'false');
    $menu.slideDown('fast');
  }

  closeFilterPanels() {
    this.$buttons.removeClass('btn-open');
    this.$buttons.attr('aria-expanded', 'false');
    this.$menus.removeClass('menu-item--open');
    this.$menus.attr('aria-hidden', 'true');
    this.$menus.slideUp();
  }

  toggleButton(e) {
    const $button = $(e.currentTarget);
    if ($button.attr('aria-expanded') === 'true') {
      this.closeFilterPanels();
    }
    else {
      const menuSelector = $button.attr('data-toggle');
      const $menu = $(menuSelector, this);
      this.openFilterPanel($button, $menu);
    }
  }

}

window.customElements.define('flynt-block-faculty-directory', BlockFacultyDirectory, { extends: 'div' });