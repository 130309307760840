import './scripts/publicPath'
import 'console-polyfill'
import 'normalize.css/normalize.css'
import './main.scss'
import $ from 'jquery'
import feather from 'feather-icons'

import { TimelineMax } from 'gsap';
import ScrollMagic from 'scrollmagic';
import 'animation.gsap';
import installCE from 'document-register-element/pony'

window.jQuery = $

window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.preloadAfterLoad = true
require('lazysizes')

$(document).ready(function () {
  feather.replace({
    'stroke-width': 1
  })
})


let controller = new ScrollMagic.Controller();
var left_els = $("[data-anim='left']");
var right_els = $("[data-anim='right']");
var in_els = $("[data-anim='in']");
var up_els = $("[data-anim='up']");
var elastic_els = $("[data-anim='elastic']");


left_els.each(function() {

    var el = this;
    var offset = $(this).data("offset");
    var delay = $(this).data("delay");

    if (offset == undefined) {
        offset = 0;
    }

    if (delay == undefined) {
        delay = 0;
    }

    var tween = new TimelineMax()
        .fromTo(el, 2, { opacity: 0, x: "-=200", ease: Expo.easeOut }, { x: 0, opacity: 1, ease: Expo.easeOut })
        .delay(delay);

    var scene = new ScrollMagic.Scene({
            triggerElement: el,
            reverse: false
        })
        .triggerHook(1)
        .offset(offset)
        .setTween(tween)
        .addTo(controller);

});

right_els.each(function() {

    var el = this;
    var offset = $(this).data("offset");
    var delay = $(this).data("delay");

    if (offset == undefined) {
        offset = 0;
    }

    if (delay == undefined) {
        delay = 0;
    }

    var tween = new TimelineMax()
        .fromTo(el, 2, { opacity: 0, x: "+=200", ease: Expo.easeOut }, { x: 0, opacity: 1, ease: Expo.easeOut })
        .delay(delay);

    var scene = new ScrollMagic.Scene({
            triggerElement: el,
            reverse: false
        })
        .triggerHook(1)
        .offset(offset)
        .setTween(tween)
        .addTo(controller);

});

up_els.each(function(index) {
    var el = this;
    var offset = $(this).data("offset");
    var delay = $(this).data("delay");

    if (offset == undefined) {
        offset = 0;
    }

    if (delay == undefined) {
        delay = 0;
    }

    var tween = new TimelineMax()
        .fromTo(el, 1 + (index * 0.5), { opacity: 0, y: `+=${200}`, ease: Expo.easeOut }, { y: 0, opacity: 1, ease: Expo.easeOut })
        .delay(delay);

    var scene = new ScrollMagic.Scene({
            triggerElement: el,
            reverse: false
        })
        .triggerHook(1)
        .offset(offset)
        .setTween(tween)
        .addTo(controller);

});

in_els.each(function() {

    var el = this;
    var offset = $(this).data("offset");
    var delay = $(this).data("delay");

    if (offset == undefined) {
        offset = 0;
    }

    if (delay == undefined) {
        delay = 0;
    }

    var tween = new TimelineMax()
        .to(el, 0.8, {
            opacity: 1,
            ease: Circ.easeOut,
            delay: 0.2
        })
        .delay(delay);

    var scene = new ScrollMagic.Scene({
            triggerElement: el,
            reverse: false
        })
        .triggerHook(1)
        .offset(offset)
        .setTween(tween)
        .addTo(controller);

});


elastic_els.each(function() {

    var el = this;
    var children = $(this).children('div');
    var offset = $(this).data("offset");

    if (offset == undefined) {
        offset = 200;
    }

    var tween = new TimelineMax()
        .staggerFromTo(children, 1.5, {
            y: 30,
            rotationX: -20,
            opacity: 0,
            delay: 0,
            ease: Expo.easeOut,
        }, { y: 0, rotationX: 0, opacity: 1, ease: Expo.easeOut }, 0.3);

    var scene = new ScrollMagic.Scene({
            triggerElement: el,
            reverse: false
        })
        .triggerHook(1)
        .offset(offset)
        .setTween(tween)
        //.addIndicators()
        .addTo(controller);

});

installCE(window, {
  type: 'force',
  noBuiltIn: true
})

function importAll (r) {
  r.keys().forEach(r)
}

importAll(require.context('../Components/', true, /\/script\.js$/))

$('a[href="#main-content"]').on('click', function () {
    $('#main-content').focus();
});

if (typeof(jQuery.fn.sbiOwlCarousel) == 'undefined') {
    jQuery.fn.sbiOwlCarousel = function() {
        if (typeof(console) != 'undefined') {
            console.log('Creating sbiOwlCarousel function stub to work around SmashBaloons click error');
        }
        return false;
    }
}
