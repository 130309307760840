import $ from 'jquery'

let myMovie=document.getElementById('myMovie');
let progressBar=document.getElementById('progressBar');
let barSize = 0;
let barElement = document.getElementById('defaultBar');
if (barElement) {
	barSize = barElement.offsetWidth;
}

function doFirst(){
	let playButton=document.getElementById('playButton');
	playButton.addEventListener('click', playOrPause, false);
	let updateBar=setInterval(update, 500);
}

function playOrPause() {
	if (!myMovie.paused && !myMovie.ended){
		myMovie.pause();
    playButton.innerHTML='Play';
    playButton.classList.toggle('playing');
		window.clearInterval(updateBar);
	} else {
		myMovie.play();
    playButton.innerHTML='Pause';
    playButton.classList.toggle('playing');
		let updateBar=setInterval(update, 500);
	}
}

function update() {
	if (!myMovie.ended) {
		var size=parseInt(myMovie.currentTime*barSize/myMovie.duration);
		progressBar.style.width=size+'px';
	} else {
		progressBar.style.width='0px';
		playButton.innerHTML='Play';
		window.clearInterval(updateBar);
	}
}

if (myMovie) {
	window.addEventListener('load',doFirst,false);
}