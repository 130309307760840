import {basictable} from './basictable.js';

class BlockWysiwyg extends window.HTMLDivElement {

  constructor (self) {
    self = super(self);

    this.tableElements = this.querySelectorAll('table');
    for (let tableElement of this.tableElements) {
      tableElement.classList.add('responsive-table');
    }

    new basictable('.responsive-table');

    return self;
  }

}

window.customElements.define('flynt-block-wysiwyg', BlockWysiwyg, { extends: 'div' });
jQuery(function($){
  $('td,th,tr').removeAttr('style');
  $('td,th,table').removeAttr('width');
  });
  