require('waypoints/lib/noframework.waypoints.min');
import $ from 'jquery'
class SearchOverlay extends window.HTMLDivElement {

  constructor (self) {
    self = super(self);
    self.initSearchOverlay();
    this.overlaySelector = '.search-overlay';
    return self;
  }
  
  getWindowScrollTop() {
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  getViewportHeight() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  }

  getMainContentOffsetTop() {
    const rect = this.mainContentElement.getBoundingClientRect();
    const scrollTop = this.getWindowScrollTop();
    return rect.top + scrollTop;
  }

  focusinHandler(event) {
    if (event.target.closest(this.overlaySelector)) {
      // do nothing
    }
    else {
      this.hideOverlay();
    }
  }

  showOverlay() {
    clearTimeout(this.hideOverlayTimeout);
    this.style.display = 'block';
    this.offsetHeight; // Forces repaint
    this.classList.add(this.showClassName);
    for (const overlayTriggerElement of this.overlayTriggerElements) {
      overlayTriggerElement.classList.add(this.overlayTriggerActiveClassName);
    }
    document.addEventListener('focusin', this.focusinHandler.bind(this));
  }
  
  hideOverlay() {
    const transitionTime = 250;
    this.hideOverlayTimeout = setTimeout(() => {
      this.style.display = 'none';
    }, transitionTime);
    this.classList.remove(this.showClassName);
    for (const overlayTriggerElement of this.overlayTriggerElements) {
      overlayTriggerElement.classList.remove(this.overlayTriggerActiveClassName);
    }
    document.removeEventListener('focusin', this.focusinHandler.bind(this));
  }

  

  initOverlayTrigger() {
    for (const overlayTriggerElement of this.overlayTriggerElements) {
      overlayTriggerElement.addEventListener('click', this.handleOverlayTrigger.bind(this));
      document.addEventListener('keyup', this.closeOverlayEsc.bind(this));
    }
  }

  handleOverlayTrigger(event) {
    if (this.classList.contains(this.showClassName)) {
      this.hideOverlay();
    }
    else {
      this.showOverlay();
    }
    
  }

  closeOverlayEsc(e) {
    if (e.key === 'Escape') {
 
      this.hideOverlay();
 
  
  }
  }

  initOverlaySticky() {
    if (this.getWindowScrollTop() < this.getMainContentOffsetTop()) {
      this.classList.add(this.stuckToTopClassName);
    }
    const mainContentTopWaypoint = new Waypoint({
        element: this.mainContentElement,
        handler: (direction) => {
          if (direction === 'down') {
            this.classList.remove(this.stuckToTopClassName);
          }
          else if (direction === 'up') {
            this.classList.add(this.stuckToTopClassName);
          }
        }
    });
    const mainContentBottomWaypoint = new Waypoint({
        element: this.mainContentElement,
        offset: 'bottom-in-view',
        handler: (direction) => {
          if (direction === 'down') {
            this.classList.add(this.stuckToBottomClassName);
          }
          else if (direction === 'up') {
            this.classList.remove(this.stuckToBottomClassName);
          }
        }
    });
  }



  initSearchOverlay() {
    this.className = 'search-overlay';
    this.selector = '.' + this.className;
    this.showClassName = this.className + '--show';
    this.stuckToTopClassName = this.className + '--stuck-to-top';
    this.stuckToBottomClassName = this.className + '--stuck-to-bottom';
    this.overlayTriggerActiveClassName = 'active';
    this.mainHeaderSelector = '.mainHeader';
    this.mainHeaderElement = document.querySelector(this.mainHeaderSelector);
    this.mainContentSelector = '.mainContent';
    this.mainContentElement = document.querySelector(this.mainContentSelector);
    this.overlayTriggerSelectors = [
      '.main-menu__search-toggle',
      '.search-overlay__close'
    ];
    this.overlayTriggerElements = document.querySelectorAll(this.overlayTriggerSelectors);

    this.initOverlayTrigger();
    this.initOverlaySticky();


  }



}




window.customElements.define('search-overlay', SearchOverlay, { extends: 'div' });