jQuery(document).ready(function() {
  jQuery('.faculty-bio__read-more').click(function() {
    overflowState = jQuery('.faculty-bio__text').css('overflow-y');
    if (overflowState == 'hidden') {
      jQuery('.faculty-bio__text').css({'overflow-y':'visible', 'height' : 'auto'});
      jQuery('faculty-bio').css('height', 'auto');
      jQuery('.faculty-bio__read-more').html('Read Less');
    }
    else {
      jQuery('faculty-bio').css('height', '8rem');
      jQuery('.faculty-bio__text').css({'overflow-y':'hidden', 'height' : 'inherit'});
      jQuery('.faculty-bio__read-more').html('Read More <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 -6 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>');
    }
  });
});